import ThemeContext from '../../context/ThemeContext'
import './index.css'
import { Amplify, Auth } from "aws-amplify";

const Navbar = () => (
  <ThemeContext.Consumer>
    {value => {
      const { isDarkTheme, toggleTheme } = value

      const logout = () => {
        Auth.signOut().then((res, err) => {
          console.log(res)
          console.log(err)
          console.log('Sign out success')
          
        }) 
      }

      return (
        <>
          {!isDarkTheme ? (
            <div className="nav-bar-container-light">
              <div class="div-container logo-container">
                <img src="https://idemia-dsm-assets.s3.eu-west-3.amazonaws.com/assets/arrow-left.svg" alt='<' class="idm-product-icon idm-product-icon-left" />
                <label class="idm-product_name idm-bold">Innovation</label>
                <label class="idm-product_name">Dashboard&nbsp;</label>
                <img src="https://idemia-dsm-assets.s3.eu-west-3.amazonaws.com/assets/arrow-left.svg" alt='>' class="idm-product-icon idm-product-icon-right" />
              </div>
              
               {/* Edit here to add links to navbar -> Light mode */}
              <ul className="middle-items">
                {/* <li className="list-item">
                  <button
                    onClick={ logout() }
                  >logout</button>
                </li> */}
              </ul>
              <button
                type="button"
                className="theme-button"
                testid="theme"
                onClick={toggleTheme}
              >
                <img
                  src="https://assets.ccbp.in/frontend/react-js/dark-theme-img.png"
                  className="theme-img"
                  alt="theme"
                />
              </button>
              {/* <button
                type="button"
                className="theme-button"
                testid="theme"
                onClick={logout()}
              >
                <img
                  src="./images/log-out.png"
                  className="refresh-status"
                  alt="theme"
                />
              </button>  */}
            </div>
          ) : (
            <div className="nav-bar-container-dark">
              <div class="div-container logo-container">
                <img src="https://idemia-dsm-assets.s3.eu-west-3.amazonaws.com/assets/arrow-left.svg" alt='<' class="idm-product-icon idm-product-icon-left" />
                <label class="idm-product_name idm-bold">Innovation</label>
                <label class="idm-product_name">Dashboard&nbsp;</label>
                <img src="https://idemia-dsm-assets.s3.eu-west-3.amazonaws.com/assets/arrow-left.svg" alt='>' class="idm-product-icon idm-product-icon-right" />
              </div>
              {/* 
              <ul className="middle-items">
                <li className="list-item">
                  <Link to="/" className="link-dark">
                    Home
                  </Link>
                </li>
                <li className="list-item">
                  <Link to="/about" className="link-dark">
                    About
                  </Link>
                </li>
              </ul> */}
              <button
                type="button"
                className="theme-button"
                testid="theme"
                onClick={toggleTheme}
              >
                <img
                  src="https://assets.ccbp.in/frontend/react-js/light-theme-img.png"
                  className="theme-img"
                  alt="theme"
                />
              </button>
            </div>
          )}
        </>
      )
    }}
  </ThemeContext.Consumer>
)

export default Navbar